import React, {useContext, useState} from 'react';
import './LanguageSwitcher.scss';
import {i18nContext, I18nLocale} from '../../../../../components/i18n/I18nProvider';

type LanguageSwitcher = {
    onLocaleSwitch?: () => void;
}

const LanguageSwitcher: React.FC<LanguageSwitcher> = (props) => {
    const {locale, locales, changeLocale} = useContext(i18nContext);
    const [isOpen, setOpen] = useState(false);
    const handleToggleOpenLanguageDropdown = () => {
        setOpen(!isOpen);
    }
    const handleBlurOpenLanguageTrigger = (event: any) => {
        if (!event.target.parentElement.contains(event.relatedTarget)) {
            setOpen(false);
        }
    }
    const handleSwitchLanguage = (locale: I18nLocale) => {
        localStorage.setItem('locale', locale.id);
        changeLocale(locale.id);
        setOpen(false);
        props.onLocaleSwitch && props.onLocaleSwitch();
    }
    return (<div className="language-switcher">
        <button
            className="language-switcher-trigger"
            onClick={handleToggleOpenLanguageDropdown}
            onBlur={handleBlurOpenLanguageTrigger}>
            <span className="language-switcher-name">{locale}</span>
            <i className="language-switcher-icon icon-arrow-down" />
        </button>
        {
            isOpen && <div className="language-switcher-dropdown">
                {locales.map((locale) => {
                    return (<button key={locale.name} className="language-switcher-dropdown-item" onClick={() => handleSwitchLanguage(locale)}>
                        {locale.name}
                    </button>)
                })}
            </div>
        }
    </div>)
}

export default LanguageSwitcher;
