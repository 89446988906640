import React, {useContext} from 'react';
import Button from '../../../../components/ui/Button/Button';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import MunicipalitiesCombobox from '../../../../components/ui/MunicipalitiesCombobox/MunicipalitiesCombobox';
import {Municipality} from '../../../../store/components/RealEstateStore';
import './Searcher2.scss';

type Searcher2Props = {
    onSelect: (municipality: Municipality | null) => void;
    onApply: () => void;
}

const Searcher2: React.FC<Searcher2Props> = (props) => {
    const {i18n} = useContext(i18nContext);

    const handleSelectMunicipality = (municipality: Municipality | null) => {
        props.onSelect(municipality);
    }
    const handleApplyStreet = () => {
        props.onApply();
    }

    return (<div className="searcher">
        <div className="searcher__field">
            <div className="searcher__field-city">
                <i className="icon-valencia" />
                Valencia
            </div>
            <div className="searcher__field-input">
                <MunicipalitiesCombobox onSelect={handleSelectMunicipality} mode={'clear'} icon={'icon-search'}/>
            </div>
            <div className="searcher__field-button" onClick={handleApplyStreet}>
                <i className="icon-search"/>
            </div>
        </div>
    </div>)
}

export default Searcher2;
