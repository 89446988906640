import React, {useContext, useEffect, useState} from 'react';
import './Footer.scss';
import logotype from '../Header/logotype_white.png';
import {Link, useLocation} from 'react-router-dom';
import {i18nContext} from '../../../components/i18n/I18nProvider';
import main from '../../Buy/images/mail.webp';
import call from '../../Buy/images/call.png';

type FooterProps = {
    onOpenEvaluate: () => void;
    onOpenFeedback: (type: string) => void;
}
const Footer: React.FC<FooterProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const [isBuyPage, setBuyPage] = useState(true);

    const location = useLocation();
    useEffect(() => {
        setBuyPage(window.location.href.includes('/buy'));
    }, [location]);
    return (<footer className="footer">
        <div className="show-xs show-sm hide-md hide-lg">
            <div className="real-estate-contacts">
                <div className="contacts-print">
                    <img src={logotype} alt={'domus'} />
                </div>
                <div className="real-estate-contacts__description">
                    <div className="contact-row">
                        <i className="icon-location" />
                        Plaza del Ayuntamiento 19, office 3G, Valencia
                    </div>
                    <a className="contact-row" href="tel:+34624294452">
                        <i className="icon-phone" />
                        +34 624 29 44 52
                    </a>
                    <a className="contact-row" href="mailto:info@reselecta.com?subject=Question regarding selecta">
                        <i className="icon-main" />
                        info@reselecta.com
                    </a>
                </div>
                <div className="contacts-social">
                    <div className="social-icon" onClick={() => props.onOpenFeedback('mail')}>
                        <i className="icon-main" />
                    </div>
                    <div className="social-icon" onClick={() => props.onOpenFeedback('call')}>
                        <i className="icon-phone1" />
                    </div>
                </div>
            </div>
        </div>
        <div className="row middle-xs middle-sm middle-md middle-lg middle-xl hide-xs hide-sm show-md show-lg">
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 contacts">
                <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                    <div className="col-xs-8 col-sm-12 col-md-12 col-lg-12">
                        <div className="footer__navigation-item-wrapper">
                            <i className="icon-location" />
                            <span>
                            Plaza del Ayuntamiento 19, office 3G, Valencia
                        </span>
                        </div>
                    </div>
                </div>
                <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <a className="footer__navigation-item footer__navigation-item-wrapper" href="tel:+34624294452">
                            <i className="icon-phone" />
                            <span>+34 624 29 44 52</span>
                        </a>
                    </div>
                </div>
                <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <a className="footer__navigation-item footer__navigation-item-wrapper" href="mailto:info@reselecta.com?subject=Question regarding selecta">
                            <i className="icon-main" />
                            <span>info@reselecta.com</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="hide-xs hide-sm show-md show-xl show-lg col-md-3 col-lg-4 navigation-middle">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <img src={logotype} alt={'domus logotype'} />
                    </div>
                </div>
            </div>
            <div className="hide-xs hide-sm show-md show-xl show-lg col-md-4 col-lg-4 navigation-right">
                <div className="row end-md end-lg">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span className="footer__navigation-item" onClick={() => {props.onOpenEvaluate();}}>
                            {i18n.t('footer.links.evaluate')}
                        </span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Link className="footer__navigation-item" to={'/how-it-works'}>
                            {i18n.t('footer.links.howItWorks')}
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {
                            isBuyPage ?
                                <span className="footer__navigation-item" onClick={
                                    () => document.getElementById('about')?.scrollIntoView()
                                }>{i18n.t('footer.links.about')}</span>
                                :
                                <Link className="footer__navigation-item" to={'/buy'} onClick={() => {
                                    setTimeout(() => document.getElementById('about')?.scrollIntoView(), 50);
                                }}>{i18n.t('footer.links.about')}</Link>
                        }
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Link className="footer__navigation-item" to={'/privacy'}>
                            {i18n.t('footer.links.privacy')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>)
}

export default Footer;
