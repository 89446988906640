import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import Root from './routes/Root/Root';
import {createHashRouter, Navigate, RouterProvider} from 'react-router-dom';
import {store} from './store/store';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import {Provider as MobxProvider} from 'mobx-react';
import Buy from './routes/Buy/Buy';
import I18nProvider from './components/i18n/I18nProvider';
import PrivacyPolicy from './routes/PrivacyPolicy/PrivacyPolicy';
import ExtraServices from './routes/ExtraServices/ExtraServices';
import HowItWorks from './routes/HowItWorks/HowItWorks';
import Catalog from './routes/Catalog/Catalog';
import CatalogCardDetails from './routes/CatalogCardDetails/CatalogCardDetails';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createHashRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                index: true,
                element: <Navigate replace to={'buy'} />
            },
            {
                path: "/buy",
                element: <Buy />
            },
            {
                path: "/privacy",
                element: <PrivacyPolicy />
            },
            {
                path: "/extras",
                element: <ExtraServices />
            },
            {
                path: "/how-it-works",
                element: <HowItWorks />
            },
            {
                path: "/catalog",
                element: <Catalog />
            },
            {
                path: "/catalog/realEstate/:id",
                element: <CatalogCardDetails />
            }
        ],
    },
    {
        path:"*",
        element:<Navigate to="/buy" replace />
    }
]);

root.render(
  <React.StrictMode>
      <MobxProvider store={store}>
          <I18nProvider>
              <ErrorBoundary>
                  <RouterProvider router={router} />
              </ErrorBoundary>
          </I18nProvider>
      </MobxProvider>
  </React.StrictMode>
);

reportWebVitals();
