import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './Step1.scss';
import {observer} from 'mobx-react';
import {Tooltip} from 'react-tooltip';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {
    realEstateStoreContext,
    RealEstateType,
    realEstateTypes,
    Street
} from '../../../../../../store/components/RealEstateStore';
import Select from '../../../../../../components/ui/Select/Select';
import FieldText from '../../../../../../components/ui/FieldText/FieldText';
import Button from '../../../../../../components/ui/Button/Button';
import StreetsCombobox from '../../../../../../components/ui/StreetsCombobox/StreetsCombobox';

type Step1Props = {
    onNextStep: () => void;
}

const Step1: React.FC<Step1Props> = observer((props) => {
    const {i18n, locale} = useContext(i18nContext);
    const realEstates = useMemo(() => JSON.parse(JSON.stringify(realEstateTypes)).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [locale, i18n.translations]);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {setValue, handleSubmit} = methods;
    const getRealEstate = useCallback(() => {
        const realEstateType = realEstateStoreCtx.realEstate.realEstateType;
        return realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
    }, [realEstateStoreCtx.realEstate.realEstateType, realEstates]);

    const [realEstateInitBy, setRealEstateInitBy] = useState<RealEstateType | null>(getRealEstate());

    useEffect(() => {
        const type = getRealEstate();
        setRealEstateInitBy(type ? type : null);
        setValue('realEstateType', type ? type.value : '');
    }, [realEstateStoreCtx.realEstate.realEstateType, setValue, realEstates, setRealEstateInitBy, getRealEstate]);
    const handleChangeRealEstateType = (realEstate: RealEstateType) => {
        realEstateStoreCtx.clearRealEstate();
        realEstateStoreCtx.mergeRealState({realEstateType: realEstate.value});
    }
    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        realEstateStoreCtx.mergeRealState({[fieldName]: event.target.value});
    }
    const handleSelectStreet = (addressObj: Street) => {
        realEstateStoreCtx.mergeRealState({
            municipality: addressObj?.municipality ?? '',
            postcode: addressObj?.postcode ?? '',
            street: addressObj?.street ?? '',
            presentationAddress: addressObj?.presentationAddress ?? ''
        });
        if (addressObj?.postcode) {
            setValue('postcode', addressObj?.postcode ?? '');
            methods.trigger('postcode').then(() => {});
        }
    }
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        props.onNextStep();
    }

    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content modal-content-step1">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <Select
                            prefixTemplate={(item: RealEstateType) => <i className={`${item.iconName}`}/>}
                            items={realEstates}
                            initBy={realEstateInitBy}
                            textKey={'name'}
                            registerProps={{name: 'realEstateType', options: {required: true}}}
                            callback={handleChangeRealEstateType}
                            defaultText={i18n.t('sellModal.step1.realEstateTypePlaceholder')}
                        />
                    </div>
                </div>
                {
                    realEstateStoreCtx.realEstate.realEstateType &&
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <StreetsCombobox
                                initBy={realEstateStoreCtx.realEstate.presentationAddress}
                                placeholder={i18n.t('sellModal.step1.searchAddressPlaceholder')}
                                onSelectStreet={handleSelectStreet}
                                registerProps={{name: 'presentationAddress', options: {required: true}}}
                            />
                        </div>
                    </div>
                }
                {['house', 'commercial', 'land'].includes(realEstateStoreCtx.realEstate.realEstateType) && <>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.houseNumberPlaceholder')}
                                value={realEstateStoreCtx.realEstate.houseNumber ?? ''}
                                registerProps={{
                                    name: 'houseNumber',
                                    options: {required: true, onChange: handleChangeFieldText}
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.postcodePlaceholder')}
                                value={realEstateStoreCtx.realEstate.postcode ?? ''}
                                type="tel"
                                registerProps={{
                                    name: 'postcode',
                                    options: {required: true, onChange: handleChangeFieldText}
                                }}
                            />
                        </div>
                    </div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 field-with-postfix">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.cadastralNumberPlaceholder')}
                                name="cadastralNumber"
                                value={realEstateStoreCtx.realEstate.cadastralNumber ?? ''}
                                onChange={handleChangeFieldText}
                            />
                            <i className="field-postfix icon-info cadastral-number"/>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4"></div>
                    </div>
                    <Tooltip
                        anchorSelect=".icon-info.cadastral-number">{i18n.t('sellModal.step1.cadastralNumberTooltip')}</Tooltip>
                </>}
                {realEstateStoreCtx.realEstate.realEstateType === 'apartment' && <>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.houseNumberPlaceholder')}
                                value={realEstateStoreCtx.realEstate.houseNumber ?? ''}
                                registerProps={{
                                    name: 'houseNumber',
                                    options: {required: true, onChange: handleChangeFieldText}
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.ladderPlaceholder')}
                                name="ladder"
                                value={realEstateStoreCtx.realEstate.ladder ?? ''}
                                onChange={handleChangeFieldText}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.floorPlaceholder')}
                                value={realEstateStoreCtx.realEstate.floor ?? ''}
                                registerProps={{
                                    name: 'floor',
                                    options: {required: true, onChange: handleChangeFieldText}
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.doorPlaceholder')}
                                name="door"
                                value={realEstateStoreCtx.realEstate.door}
                                onChange={handleChangeFieldText}
                            />
                        </div>
                    </div>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.postcodePlaceholder')}
                                value={realEstateStoreCtx.realEstate.postcode ?? ''}
                                type="tel"
                                registerProps={{
                                    name: 'postcode',
                                    options: {required: true, onChange: handleChangeFieldText}
                                }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 field-with-postfix">
                            <FieldText
                                placeholder={i18n.t('sellModal.step1.cadastralNumberPlaceholder')}
                                name="cadastralNumber"
                                value={realEstateStoreCtx.realEstate.cadastralNumber ?? ''}
                                onChange={handleChangeFieldText}
                            />
                            <i className="field-postfix icon-info cadastral-number"/>
                        </div>
                    </div>
                    <Tooltip
                        anchorSelect=".icon-info.cadastral-number">
                        {i18n.t('sellModal.step1.cadastralNumberTooltip')}
                    </Tooltip>
                </>}
            </div>
            {realEstateStoreCtx.realEstate.realEstateType && <div className="modal-actions">
                <div className="row center-xs">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 ta-center">
                        <Button className="button" type="submit">{i18n.t('sellModal.step1.button')}</Button>
                    </div>
                </div>
            </div>}
        </form>
    </FormProvider>)
});

type Inputs = {
    realEstateType: string,
    presentationAddress: string,
    houseNumber: string,
    floor: string,
    postcode: string,
};

export default Step1;
