import React from 'react';
import './RealEstateCardSkileton.scss';
import homeSkileton from './home-placeholder.svg';

const RealEstateCardSkileton: React.FC<{}> = ({}) => {
    return (<div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div className="real-estate-card-link real-estate-card-link--placeholder">
            <div className="real-estate-card-preview">
                <div className="real-estate-catalog-preview">
                    <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.139 155.139">
                        <g>
                            <g>
                                <path d="M125.967,51.533V20.314h-16.862V38.06L77.569,12.814L0,74.869h21.361v67.455h112.416v-67.45h21.361
                                 L125.967,51.533z M125.925,134.472H97.546V82.37H57.58v52.103H29.202V71.146l48.356-38.689l48.355,38.689v63.326H125.925z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            <div className="real-estate-description">
                <div className="real-estate-features">
                    <div className="real-estate-feature">
                        <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
                    </div>
                    <div className="real-estate-feature-separator"></div>
                    <div className="real-estate-feature">
                        <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
                    </div>
                    <div className="real-estate-feature-separator"></div>
                    <div className="real-estate-feature">
                        <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
                    </div>
                </div>
                <div className="real-estate-address">
                    <div className="real-estate-address-location">
                        <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
                    </div>
                    <div className="real-estate-address-presentation">
                        <div className="skeleton__rect skeleton__rect--w-full skeleton__rect--h-20"/>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default RealEstateCardSkileton;
