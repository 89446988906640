import React, {useContext, useEffect, useState} from 'react';
import './Buy.scss';
import banner from './images/banner-background.jpg';
import logotypeWhite from './images/logotype_white.png';
import card1 from './images/card1.webp';
import card2 from './images/card2.webp';
import card3 from './images/card3.webp';
import about from './images/about.webp';
import parallax from './images/parallax.webp';
import contacts from './images/contacts.webp';
import main from './images/mail.webp';
import call from './images/call.png';
import {Municipality, RealEstate, realEstateStoreContext} from '../../store/components/RealEstateStore';
import Button from '../../components/ui/Button/Button';
import {observer} from 'mobx-react';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useNavigate, useOutletContext} from 'react-router-dom';
import Searcher2 from './components/Searcher2/Searcher2';
import RealEstateCard from '../Catalog/components/RealEstateCard/RealEstateCard';

const Buy = observer(() => {
    const navigate = useNavigate();
    const {i18n, locale} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const {setFeedbackModalProps, setPrivacyModalProps, setClientModalProps} = useOutletContext<any>();
    const [questions, setQuestions] = useState(faqQuestions);
    const [municipality, setMunicipality] = useState<Municipality | null>(null);
    const [realEstates, setRealEstates] = useState<RealEstate[]>([] as RealEstate[]);
    const handleSelectStreet = (municipality: Municipality | null) => setMunicipality(municipality);
    const handleApplySearch = () => {
        if (municipality) {
            navigate(`/catalog?realEstateType=&municipality=${municipality.name}&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        } else {
            navigate(`/catalog?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    const handleToggleOpenQuestion = (question: FaqQuestion) => {
        const qs = questions.filter((q) => q.id !== question.id);
        setQuestions([...qs, ...[{...question, ...{isOpen: !question.isOpen}}]].sort((questionA, questionB) => questionA.id > questionB.id ? 1 : -1));
    }

    const handleOpenEvaluate = () => {
        window.scrollTo(0, 0);
        setPrivacyModalProps({isOpen: true})
    }

    useEffect(() => {
        realEstateStoreCtx.loadBestRealEstates().then((realEstates) => setRealEstates(realEstates));
    }, []);

    return (<div className="home">
        <section className="home__banner">
            <div className="home__banner-scroller"></div>
            <div className="home__banner-image">
                <img src={banner} alt="domus banner"/>
            </div>
            <div className="home__banner-text">
                <img className="home__banner-logotype" src={logotypeWhite} alt="domus banner"/>
                <h4 className="show-xs hide-sm hide-md hide-lg">
                    <p>{i18n.t('buy.banner.descriptionTop')}</p>
                    <p>{i18n.t('buy.banner.descriptionBottom')}</p>
                </h4>
                {/*<Searcher onSelect={() => {}} onApply={handleApplySearch}/>*/}
                <Searcher2 onSelect={handleSelectStreet} onApply={handleApplySearch}/>
                <h4 className="hide-xs show-sm show-md show-lg">
                    <p>{i18n.t('buy.banner.descriptionTop')}</p>
                    <p>{i18n.t('buy.banner.descriptionBottom')}</p>
                </h4>
            </div>
        </section>
        {
            realEstates.length > 0 && <section className="home__best-offers">
                <h2>{i18n.t('buy.bestOffers.title')}</h2>
                <div className="row">
                    {realEstates.map((realEstate: RealEstate) => <RealEstateCard realEstate={realEstate} key={realEstate.id}/>)}
                </div>
            </section>
        }
        <section className="home__headline" id={"about"}>
            <div className="row middle-sm middle-xl middle-md middle-lg middle-xs center-xs center-sm center-md center-lg">
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-7">
                    <h2>{i18n.t('buy.why.title')}</h2>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-7">
                    <p>
                        {i18n.t('buy.why.descriptionTop')}
                    </p>
                    <p className="hide-xs hide-sm show-md show-xl show-lg">
                        {i18n.t('buy.why.descriptionBottom')}
                    </p>
                </div>
            </div>
        </section>
        <section className="home__cards">
            <div className="home__cards-wrapper">
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card1} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card1.title')}</h5>
                        <p>{i18n.t('buy.cards.card1.description')}</p>
                    </div>
                </div>
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card2} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card2.title')}</h5>
                        <p>{i18n.t('buy.cards.card2.description')}</p>
                    </div>
                </div>
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card3} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card3.title')}</h5>
                        <p>{i18n.t('buy.cards.card3.description')}</p>
                    </div>
                </div>
            </div>
            <div className="row middle-sm middle-xl middle-md middle-lg middle-xs center-md center-lg home__cards-actions">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 ta-center">
                    <Button onClick={handleOpenEvaluate}>{i18n.t('buy.cards.bottomButton')}</Button>
                </div>
            </div>
        </section>
        <section className="home__about">
            <div className="row between-xs between-sm between-md between-lg middle-xs middle-sm middle-md middle-lg home__about-content">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4 hide-xs hide-sm show-md show-lg show-xl">
                    <img src={about} alt="about domus"/>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                    <h3>{i18n.t('buy.about.title')}</h3>
                    <p>
                        {i18n.t('buy.about.description')}
                    </p>
                    <div className="home__about-actions">
                        <Button onClick={handleOpenEvaluate}>{i18n.t('buy.about.bottomButton')}</Button>
                    </div>
                </div>
            </div>
        </section>
        <section className="home__parallax">
            <div className="row">
                <div className="col">
                    <img src={parallax} alt={'domus'}/>
                </div>
            </div>
        </section>
        {/*<section className="home__faq">*/}
        {/*    <h4>FAQ</h4>*/}
        {/*    {*/}
        {/*        questions.map((question) => {*/}
        {/*            return (*/}
        {/*                <div className={`question ${question.isOpen ? 'question-open' : ''}`} key={question.id}>*/}
        {/*                    <div className="question-header" onClick={() => handleToggleOpenQuestion(question)}>*/}
        {/*                        <h6>{question.question}</h6>*/}
        {/*                        <i className="icon-close"/>*/}
        {/*                    </div>*/}
        {/*                    <div className="question-description">*/}
        {/*                        {question.description}*/}
        {/*                    </div>*/}
        {/*            </div>)*/}
        {/*        })*/}
        {/*    }*/}
        {/*</section>*/}
        <section className="home__contacts" id={'contacts'}>
            <div className="row between-xs between-sm between-md between-lg middle-xs middle-sm middle-md middle-lg home__contacts-content">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <h3>
                        {i18n.t('buy.contacts.title')}
                    </h3>
                    <p>
                        {i18n.t('buy.contacts.description')}
                    </p>
                    <div className="contacts-social">
                        <div className="social-icon" onClick={() => setFeedbackModalProps({isOpen: true, feedbackType: 'mail'})}>
                            <i className="icon-main" />
                        </div>
                        <div className="social-icon" onClick={() => setFeedbackModalProps({isOpen: true, feedbackType: 'call'})}>
                            <i className="icon-phone1" />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 hide-xs hide-sm show-md show-lg show-xl">
                    <img src={contacts} alt={'domus contacts'} />
                </div>
            </div>
        </section>
    </div>)
});

type FaqQuestion = {
    id: number;
    isOpen: boolean;
    question: string;
    description: string;
}

const faqQuestions: FaqQuestion[] = [
    {
        id: 1,
        isOpen: false,
        question: "Question 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 2,
        isOpen: false,
        question: "Question 2",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 3,
        isOpen: false,
        question: "Question 3",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        id: 4,
        isOpen: false,
        question: "Question 4",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
]

export default Buy;
